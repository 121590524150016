import { HStack, Box, VStack, useBreakpointValue } from "@chakra-ui/react"
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../../providers/AuthProvider';
import Navbar from '../common/Navbar';
import SidebarLinks from "../common/SideBarLinks";
export default function PrivateRoutes() {
    const { loading, logged } = useAuth();
    const sideBarVariant = useBreakpointValue({ base: 'drawer', md: 'sidebar' });

    if (loading) {
        return (
            <React.Fragment>
                <p>Loading...</p>
            </React.Fragment>
        )
    }
    return (
        true ?
            <>
                <HStack spacing='0'>
                    {sideBarVariant === 'sidebar' && <SidebarLinks />}
                    <Box h='100vh' w='100%' overflowY='auto'>
                        <VStack>
                            <Navbar />
                            <Outlet />
                        </VStack>
                    </Box>
                </HStack>
            </>
            : <Navigate to='/login' />
    )
}

