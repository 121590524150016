import { useFormik } from "formik";
import {
  Box,
  Button,
  FormControl,
  Wrap,
  Input,
  Image,
  VStack,
  Text,
  HStack,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import { toast } from "react-toastify";

export default function Login() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const { login} = useAuth();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      navigate('/', { state: values });
      try {
        await login(values.email, values.password);
      } catch (error) {
        toast.error("Invalid Credentials");
        console.error(error);
      }
    }
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  return (
    <HStack w='100%' h='100vh' spacing='0' alignItems='center' justifyContent='center'>
      <Box
        w={{ base: '98%', lg: '30%' }}
        display='flex'
        alignItems='center'
        justifyContent='center'
        p={{ base: 4, md: 0 }}
        shadow='md'
      >
        <form onSubmit={formik.handleSubmit}>
          <VStack
            spacing='6'
            p={6}
            w={'100%'}
            rounded="md">
            <Image w='120px' src='/logo.png' />
            <Text fontSize='2xl' as='h3' fontWeight='500' color='primary.800'>Welcome!</Text>
            <Text fontSize='2xl' as='h3' fontWeight='500'>Let’s Get Started!</Text>
            <FormControl>
              <Input
                id="email"
                name="email"
                type="email"
                variant="filled"
                required={true}
                placeholder="Enter Email"
                fontSize={'sm'}
                p={6}
                bg={'none'}
                borderColor={"gray.100"}
                _focus={{
                  borderColor: "primary.800",
                }}
                borderRadius={'50'}
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </FormControl>
            <FormControl>

              <InputGroup>
                <Input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  fontSize={'sm'}
                  required={true}
                  p={6}
                  bg={'none'}
                  borderColor={"gray.100"}
                  _focus={{
                    borderColor: "primary.800",
                  }}
                  variant="filled"
                  borderRadius={'50'}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                <InputRightElement width="4.2rem">
                  <IconButton
                    mt={2}
                    bg={'none'}
                    _hover={{ bg: "none" }}
                    size="lg"
                    onClick={togglePasswordVisibility}
                    icon={showPassword ? <FiEye /> : <FiEyeOff />}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Button
              mt={6}
              disabled={formik.isValid}
              type="submit"
              colorScheme="telegram"
              py='6'
              width="full"
              borderRadius={'50'}
              fontSize={'sm'}
            >
              Login
            </Button>
          </VStack>
        </form>
      </Box>
    </HStack>
  );
}