import React from 'react'
import { Box, Text, VStack, HStack, Image } from '@chakra-ui/react'
import { Button, FormControl, FormLabel, Input, useBreakpointValue } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import BgPic from '../../assets/Background.png';
import Logo from '../../assets/Logo/Logo.png';
import left_arrow_icon from '../../assets/icons/leftarrow.png';
import { IoIosArrowBack } from "react-icons/io";


const Forget = () => {
  const width = useBreakpointValue({ base: "100%", sm: "300px", md: "350px", lg: "380px" });
  return (
    <Box maxW='100vw' height='100vh' display='flex' justifyContent='center' alignItems='center'
      top='0px'
      left='0px'
      background='#FFFFFF 0% 0% no-repeat padding-box'
      opacity='1'
      overflow={'hidden'}
    >
      <Box
        top='-439.0457458496094px'
        left='-924.9573974609375px'
        width='1839px'
        // height={['100%','100vh', '100vh', '1520px']} 
        // h={[ '100%','1380px','1400px','1520px']} 
        position='absolute'
        zIndex='1'
      // backgroundImage={`url(${BgPic})`}
      // backgroundSize={'cover'}
      // backgroundRepeat={'no-repeat'}
      // backgroundPosition={'center'}
      >
        <Image src={`${BgPic}`} alt='bgpic' height={'1520px'} width={'1839px'}  ></Image>
      </Box>
      <Image src={`${Logo}`} alt='logo'
        top='68px'
        left='120px'
        width='149px'
        height='173px'
        opacity='1'
        zIndex={'3'}
        position='absolute'
      ></Image>
      <Box
        display='flex' justifyContent='center' alignItems='center'
        padding='12px'
        zIndex={'3'}
        // height={['400px', '400px', '430px', '430px']}
        maxW={width}
        w={'100%'}
        px='6'
        py='12'
        background='#FFFFFF 0% 0% no-repeat padding-box'
        boxShadow='0px 5px 15px #0000001F'
        borderRadius='27px'
        opacity='1'
      >
        <VStack display='flex' align='start' width='100%' spacing='4'>

          <Link to='/login' >
            <Button variant={'link'} w='50px' h='50px' borderRadius='50px' bg='gray.200'>
              <IoIosArrowBack fontSize='20px' color='#1c1c1c' /> </Button>
          </Link>



          <Text fontSize={['22px', '24px', '27px', '29px']} fontWeight='600'>Forget Password</Text>

          <FormControl  >
            <FormLabel fontSize={'16px'} paddingLeft={'6px'} width='100%'>Your Email address / Phone Number</FormLabel>
            <Input
              backgroundColor={'none'}
              width='100%'
              placeholder='Enter Your Email'
              paddingLeft={'8px'}
              marginBottom={'6px'} marginTop={'3px'}
              border={'none'}
              borderBottom={'2px'} borderBottomColor={'grey'}
              borderRadius={'none'}
              _focusVisible={'none'}
            />
          </FormControl>
          <HStack width='100%' justify='flex-end'>
            <Link to='/login'>
              <Button variant='link' color={'limegreen'}>Login</Button>
            </Link>
          </HStack>
          <Button
            w='100%'
            h='60px'
            background='transparent linear-gradient(262deg, #1EDD2D 0%, #0D8C17 100%) 0% 0% no-repeat padding-box'
            _hover={{
              bg: 'transparent linear-gradient(262deg, #1EDD2D 0%, #0D8C17 100%) 0% 0% no-repeat padding-box',
              shadow: 'sm'
            }}
            shadow='xl'
            borderRadius='20px'
            opacity='1'
            textAlign='center'
            letterSpacing='0px'
            color='#FFFFFF'
            textTransform='uppercase'
            marginBottom={6}
          >NEXT</Button>
        </VStack>
      </Box>
    </Box>
  )
}

export default Forget