import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Forget from './components/auth/Forget';
import Login from './components/auth/Login';
import PrivateRoutes from './components/utils/PrivateRoutes';
import AuthProvider from './providers/AuthProvider';
import { Suspense, lazy } from 'react';

const Dashboard = lazy(()=>import('./components/dashboard/Dashboard'));
const Users = lazy(()=>import('./components/dashboard/Users'));
const Snags = lazy(()=>import('./components/dashboard/Snags'));
const Vehicles = lazy(()=>import('./components/dashboard/Vehicles'));
const Payments = lazy(()=>import('./components/dashboard/Payments'));
const Support = lazy(()=>import('./components/dashboard/Support'));

function App() {
  return (
    <AuthProvider key={'authprovider'}>
      <Router>
       <Suspense>
       <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path='/' element={<Dashboard />} />
            <Route path='/users' element={<Users />} />
            <Route path='/snags' element={<Snags />} />
            <Route path='/vehicles' element={<Vehicles />} />
            <Route path='/payments' element={<Payments />} />
            <Route path='/support' element={<Support />} />
          </Route>
          <Route path='/login' element={<Login />} />
          <Route path='/forget' element={<Forget />} />
        </Routes>
       </Suspense>
      </Router>
    </AuthProvider>
  );
}

export default App;
