import {
    Box,
    HStack,
    useDisclosure,
    Flex,
    Text,
    useBreakpointValue,
    Menu,
    MenuButton,
    MenuList,
    VStack,
    MenuItem,
    Container,
    Card,
} from '@chakra-ui/react'
import React from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SideBar from './SideBar';
import { IoNotificationsOutline } from "react-icons/io5";

import { Link, useNavigate } from 'react-router-dom';
import { MdKeyboardArrowDown } from "react-icons/md";
import { useAuth } from '../../providers/AuthProvider';

export default function Navbar() {
    let navigate = useNavigate();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const sideBarVariant = useBreakpointValue({ base: 'drawer', md: 'sidebar' });

    const { logout } = useAuth();

    const handleLogout = () => {
        console.log('logout');
        logout();
        navigate('/login')
    };

    return (
        <>
            <Box px={4} w='100%'>
                <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>

                    {sideBarVariant === 'drawer' && (
                        <SideBar />
                    )}

                    <Flex w='100%'>
                        <HStack justifyContent='space-between' w='100%'>
                            <Text className='custom' fontSize='xl'>Welcome <span color='#1c1c1c'>Prince</span> 🖐</Text>

                            <HStack spacing={6}>

                                <Menu>
                                    <MenuButton>
                                        <HStack spacing='2'>
                                            <VStack spacing={0}>
                                                <Text fontSize={'sm'} fontWeight={600}>Prince</Text>
                                                <Text fontSize={'sm'}>Admin</Text>
                                            </VStack>
                                            <MdKeyboardArrowDown size='20' opacity={'0.8'} />
                                        </HStack>
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem minH='40px'>
                                            <Link to={'/#'}>
                                                <Text pl={1}>Settings</Text>
                                            </Link>
                                        </MenuItem>

                                        <MenuItem minH='40px' onClick={handleLogout}>
                                            <Text pl={1}>Log Out</Text>
                                        </MenuItem>

                                    </MenuList>
                                </Menu>
                            </HStack>
                        </HStack>
                    </Flex>
                </Flex>

            </Box >
            <ToastContainer />
        </>
    )
}
