import { Box, HStack, Text, VStack, Image } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { MdDashboard } from "react-icons/md";
import { FaUserGear } from "react-icons/fa6";
import { MdOutlineSubscriptions } from "react-icons/md";
import { LiaGiftsSolid } from "react-icons/lia";
import { CgNotes } from "react-icons/cg";
import { PiUsersDuotone } from "react-icons/pi";
import { FaRegStar } from "react-icons/fa";
import { IoTicketOutline } from "react-icons/io5";

export default function SidebarLinks({ onClick }) {
    return (
        <>
            <VStack
                onClick={onClick}
                alignItems='start'
                w='100%'
                overflowY='auto'
                h='100vh'
                maxW='300px'
                bg='white'
                borderTopRightRadius="40px"
                borderBottomRightRadius="40px"
            >
                <Box pt={6} w='100%'>
                    <Link to={'/#'}>
                        <HStack alignItems='center' justifyContent='center' w='100%' mb='4'>
                            <Image src='/logo.png' w='80px' />
                        </HStack>
                    </Link>
                    <Link to={'/'}>
                        <HStack _hover={{ bg: 'blue', color: '#fefefe' }} color='black' p='4'>
                            <MdDashboard size='20px' />
                            <Text fontWeight='500'>Dashboard</Text>
                        </HStack>
                    </Link>
                    <Link to={'/users'}>
                        <HStack _hover={{ bg: 'blue', color: '#fefefe' }} color='black' p='4'>
                            <FaUserGear size='20px' />
                            <Text fontWeight='500'>Users</Text>
                        </HStack>
                    </Link>
                    <Link to={'/snags'}>
                        <HStack _hover={{ bg: 'blue', color: '#fefefe' }} color='black' p='4'>
                            <FaUserGear size='20px' />
                            <Text fontWeight='500'>Snags</Text>
                        </HStack>
                    </Link>
                    <Link to={'/vehicles'}>
                        <HStack _hover={{ bg: 'blue', color: '#fefefe' }} color='black' p='4'>
                            <FaUserGear size='20px' />
                            <Text fontWeight='500'>Vehicles</Text>
                        </HStack>
                    </Link>
                    <Link to={'/payments'}>
                        <HStack _hover={{ bg: 'blue', color: '#fefefe' }} color='black' p='4'>
                            <FaUserGear size='20px' />
                            <Text fontWeight='500'>Payments</Text>
                        </HStack>
                    </Link>
                    <Link to={'/support'}>
                        <HStack _hover={{ bg: 'blue', color: '#fefefe' }} color='black' p='4'>
                            <FaUserGear size='20px' />
                            <Text fontWeight='500'>Support</Text>
                        </HStack>
                    </Link>
                </Box>
            </VStack >
        </>
    )
}
