import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, extendTheme } from '@chakra-ui/react'


const theme = extendTheme({
  colors: {
    "primary": {
      "50": "#fef5f4",
      "100": "#fbd9d1",
      "200": "#f8b6a7",
      "300": "#f48871",
      "400": "#f16b4e",
      "500": "#ec360e",
      "600": "#cb2905",
      "700": "#a42104",
      "800": "#8b1c04",
      "900": "#661403"
    }
  },
  styles: {
    global: {
      'html, body': {
        bg: 'purple.50',
      },
    },
  },
})

// removing logs in prod 
if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
